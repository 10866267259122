import React, { useEffect, useRef, useState } from "react"
import Fade from "react-reveal/Fade"
import SlideDown from "react-slidedown"
import "../../../styles/component/sparkCarousal.scss"

const SparkCarousal = props => {
  const { carousalData } = props
  const [selectedCard, setSelectedCard] = useState(0)
  const videoRef = useRef(null)

  useEffect(() => {
    carousalData.image.forEach(video => {
      const videoPreload = new Image()
      videoPreload.src = video.url
    })
  }, [carousalData])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [selectedCard])

  return (
    <section className="carousal">
      <Fade bottom delay={150} duration="1000">
        {carousalData.heading ? (
          <div className="carousal-header-wrapper Layout-container">
            <p className="heading">{carousalData.heading[0]}</p>
          </div>
        ) : (
          <></>
        )}

        <div className="carousal-container">
          <div className="video-wrapper">
            <video
              className="video"
              width={600}
              autoPlay
              loop
              muted
              preload="auto"
              ref={videoRef}
              src={carousalData.image[selectedCard].url}
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="content-wrapper">
            {carousalData.text.map((text, idx) => {
              return (
                <div
                  className={`contents ${selectedCard === idx ? "active" : ""}`}
                  key={idx}
                  onClick={() => setSelectedCard(idx)}
                >
                  <div className="cont-wrap">
                    <p className="title">{text}</p>
                    <p className="subtext">{carousalData.subtext[idx]}</p>
                    {selectedCard === idx ? (
                      <SlideDown>
                        <div className="mb-video-wrapper">
                          <video
                            className="mb-video"
                            autoPlay
                            loop
                            muted
                            preload="auto"
                            ref={videoRef}
                            src={carousalData.image[selectedCard].url}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </SlideDown>
                    ) : (
                      <></>
                    )}
                  </div>
                  <hr
                    className={`line ${selectedCard === idx ? "active" : ""}`}
                  ></hr>
                </div>
              )
            })}
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default SparkCarousal
